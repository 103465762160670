import { computed } from 'vue'
import payment from '@/store/cart/cartWorkflows/univers/TR/common/steps/payment'
import type { PaymentWorkflow, PaymentWorkflowRecord } from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'

const step: StepBuilder<PaymentWorkflow, PaymentWorkflowRecord> = {
  ...payment,
  useStep(cart, workflow, record, useAction) {
    const useStep = payment.useStep(cart, workflow, record, useAction)
    return {
      ...useStep,
      useWorkflow() {
        const useWorkflow = useStep.useWorkflow()

        return {
          ...useWorkflow,
          pricingDetails: computed(() => {
            if (useWorkflow.pricings.some((pricing) => pricing.isValidating.value)) {
              return []
            }

            const pricing = useWorkflow.pricings.find(
              (pricing) => pricing.data.value?.productCode === cart.meta.productCode,
            )

            return [
              {
                icon: 'board',
                label: 'cart.payment.pricingDetail.titlesOrdered',
                value: pricing?.data.value?.pricing?.titlesCount ?? 0,
              },
            ]
          }),
        }
      },
    }
  },
}

export default step
