import commonsCartWorkflow from '../common'
import stepBasket from './steps/basket'
import stepPayment from './steps/payment'
import stepConfirmation from './steps/confirmation'
import addBeneficiary from '@/store/cart/cartWorkflows/common/module/addBeneficiary'
import type { FrontCart, CartWorkflow } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  switch (step.config.id) {
    case stepBasket.config.id:
      return stepBasket

    case stepPayment.config.id:
      return stepPayment

    case stepConfirmation.config.id:
      return stepConfirmation

    default:
      return step
  }
})
const modules = [...commonsCartWorkflow.modules, addBeneficiary]

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.TICKET_RESTAURANT
  },
}

export default cartWorkflow
