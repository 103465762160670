import { Result } from '@badrap/result'
import importStep, { useCommonStep } from '@/store/cart/cartWorkflows/common/steps/import'
import { ImportStatus, type FieldResponse, type ImportWorkflow } from '@/components/Cart/Step/Import/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { mapRulesToLocal } from '@/types/Import'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import apiV4 from '@/services/apiV4'
import useSwrv from '@/utils/useSwrv'
import { ActionType } from '@/types/Action'
import type { PostImportRequestBody } from '@/services/carts/typesV2'
import { fetchSeparator } from '@/utils/csv'
import { usePricingSummary } from '@/store/cart/cartWorkflows/common/usePricing'

const step: StepBuilder<ImportWorkflow> = {
  ...importStep,
  useStep(cart, workflow, record, useAction) {
    const useStep = importStep.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const commonWorkflow = useCommonStep(cart, workflow, record, useAction)

        const fieldResponse = useSwrv<FieldResponse>(`carts/import/rules/${cart.meta.productCode}`, async () => {
          const fields = await apiV4.fileUpload.fetchFields(cart.meta.productCode, {
            hasFacialValue: false,
            hasDeliveryPoint: true,
          })

          if (fields.isErr) {
            throw fields.error
          }

          return {
            productCode: cart.meta.productCode,
            type: DeliveryPointType.PL_BENEF,
            fields: mapRulesToLocal(cart.meta.productCode, fields.value, record.importFields),
          }
        })

        return {
          ...commonWorkflow,
          pricings: [usePricingSummary(cart)],
          fieldResponse,
          options: [],
          settingActions: [],
          importAction: useAction({
            name: 'importFile.importAction',
            id: 'import-file',
            icon: 'document-upload',
            type: ActionType.Default,
            refresh: false,
            async execute() {
              if (commonWorkflow.file.value == null) {
                return Result.err(new Error('cart.import.errors.fileNotExist'))
              }

              const resultSeparator = await fetchSeparator(commonWorkflow.file.value)
              if (resultSeparator.isErr) {
                return Result.err(resultSeparator.error)
              }

              commonWorkflow.status.value = ImportStatus.Waiting
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              const requestBody: PostImportRequestBody = {
                fileUpload: commonWorkflow.file.value,
                separator: resultSeparator.value,
              }

              const result = await apiV4.carts.postImport(cart.remote.id, requestBody)

              if (result.isErr) {
                commonWorkflow.status.value = ImportStatus.Error
                return Result.err(result.error)
              }

              commonWorkflow.importId.value = result.value.id
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              commonWorkflow.setImportInterval()
              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default step
