import { computed } from 'vue'
import payment, {
  useBillingContent,
  useDelivery,
  commonPaymentWorkflow,
} from '@/store/cart/cartWorkflows/common/steps/payment'
import type { PaymentWorkflow, PaymentWorkflowRecord } from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { usePricing } from '@/store/cart/cartWorkflows/common/usePricing'

const step: StepBuilder<PaymentWorkflow, PaymentWorkflowRecord> = {
  ...payment,
  useStep(cart, workflow, record, useAction) {
    const useStep = payment.useStep(cart, workflow, record, useAction)
    return {
      ...useStep,
      useWorkflow() {
        const deliveryInformation = record.deliveryInformations[cart.remote.id]

        return {
          ...commonPaymentWorkflow(cart, workflow, record, [usePricing(cart)], useAction),
          showDownloadSummary: true,
          showMissingEmails: false,
          dataProps: [
            {
              deliveries: computed(() => [
                useDelivery(cart.remote, deliveryInformation, {
                  title: 'cart.delivery.load',
                  canChangeDate: false,
                  type: 'dematerialized',
                  productCode: cart.remote.description.productCode,
                  articleCode: cart.remote.description.articleCode,
                  deliveryDate: cart.remote.paymentOptions.deliveryDate,
                  description: 'cart.delivery.kscDescription',
                }),
              ]),
              billing: {
                title: 'cart.billing.title',
                description: 'cart.billing.description',
                right: useBillingContent(cart, deliveryInformation.billingAddress),
                productCode: cart.remote.description.productCode,
                articleCode: cart.remote.description.articleCode,
              },
            },
          ],
        }
      },
    }
  },
}

export default step
