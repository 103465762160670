import { Result } from '@badrap/result'
import { computed, type ComputedRef } from 'vue'
import payment, {
  useBillingContent,
  useDelivery,
  commonPaymentWorkflow,
} from '@/store/cart/cartWorkflows/common/steps/payment'
import type {
  PaymentWorkflow,
  PaymentWorkflowRecord,
  DeliveryProp,
  BillingProp,
} from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'
import apiV4 from '@/services/apiV4'
import type { DeliveryInformations } from '@/services/carts/types'
import { fetchPrices, type PricingData } from '@/types/Price'
import { useMixedPricings } from '@/store/cart/cartWorkflows/common/usePricing'

const step: StepBuilder<PaymentWorkflow, PaymentWorkflowRecord> = {
  ...payment,
  async prepareStep(cart) {
    const deliveryInformations: Record<number, DeliveryInformations> = {}
    const pricings: PricingData[] = []

    for (const linkedCart of cart.remote.linkedCarts) {
      const deliveryInformationsResult = await apiV4.carts.fetchDeliveryInformations(linkedCart.id)
      const pricingResult = await apiV4.carts.fetchPricing(linkedCart.id, false)

      if (deliveryInformationsResult.isErr) {
        return Result.err(deliveryInformationsResult.error)
      }

      if (pricingResult.isErr) {
        return Result.err(pricingResult.error)
      }

      deliveryInformations[linkedCart.id] = deliveryInformationsResult.value
      pricings[linkedCart.id] = {
        readonly: false,
        productCode: cart.remote.description.productCode,
        prices: fetchPrices(pricingResult.value),
        pricing: pricingResult.value,
      }
    }

    const totalItemsResult = await apiV4.carts.headItems(cart.remote.id)
    if (totalItemsResult.isErr) {
      return Result.err(totalItemsResult.error)
    }

    return Result.ok({ deliveryInformations, totalItems: totalItemsResult.value, pricings })
  },
  useStep(cart, workflow, record, useAction) {
    const useStep = payment.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow() {
        const useWorkflow = commonPaymentWorkflow(
          cart,
          workflow,
          record,
          useMixedPricings(cart.remote.linkedCarts),
          useAction,
        )

        const dataProps =
          cart.remote.linkedCarts.reduce<{ deliveries: ComputedRef<DeliveryProp[]>; billing: BillingProp }[]>(
            (r, linkedCart) => {
              const deliveryInformation = record.deliveryInformations[linkedCart.id]
              if (linkedCart.description.productCode === ProductCode.CARTE_TICKET_RESTAURANT) {
                const deliveries = computed<DeliveryProp[]>(() => {
                  const _deliveries: DeliveryProp[] = [
                    useDelivery(linkedCart, deliveryInformation, {
                      title: 'cart.delivery.loadCardTitle',
                      canChangeDate: true,
                      type: 'dematerialized',
                      productCode: linkedCart.description.productCode,
                      articleCode: linkedCart.description.articleCode,
                      deliveryDate: linkedCart.paymentOptions.deliveryDate,
                    }),
                  ]

                  if (useWorkflow.pricings.some((pricing) => pricing.isValidating.value)) {
                    return _deliveries
                  }

                  const pricingCtr = useWorkflow.pricings.find(
                    (pricing) => pricing.data.value?.productCode === ProductCode.CARTE_TICKET_RESTAURANT,
                  )
                  const cardsCount = pricingCtr?.data.value?.pricing?.cardsCount ?? 0
                  const titlesCount = pricingCtr?.data.value?.pricing?.titlesCount ?? 0

                  if (cardsCount > 0 || (cardsCount === 0 && titlesCount === 0)) {
                    _deliveries.push(
                      useDelivery(linkedCart, deliveryInformation, {
                        title: 'cart.delivery.cardTitle',
                        canChangeDate: false,
                        type: 'default',
                        description:
                          linkedCart.options.isHomeDelivery || deliveryInformation.deliveryPoint?.isHomeDelivery
                            ? 'cart.delivery.description'
                            : 'cart.delivery.atCompanyDescription',
                        productCode: linkedCart.description.productCode,
                        articleCode: linkedCart.description.articleCode,
                        deliveryDate: null,
                      }),
                    )
                  }

                  return _deliveries
                })

                r.push({
                  deliveries,
                  billing: {
                    title: 'cart.billing.cardTitle',
                    description: 'cart.billing.description',
                    right: useBillingContent(cart, deliveryInformation.billingAddress),
                    productCode: linkedCart.description.productCode,
                    articleCode: linkedCart.description.articleCode,
                  },
                })
              } else if (linkedCart.description.productCode === ProductCode.TICKET_RESTAURANT) {
                r.push({
                  deliveries: computed(() => [
                    useDelivery(linkedCart, deliveryInformation, {
                      title: 'cart.delivery.paperTitle',
                      canChangeDate: true,
                      type: 'default',
                      description: 'cart.delivery.description',
                      productCode: linkedCart.description.productCode,
                      articleCode: linkedCart.description.articleCode,
                      deliveryDate: linkedCart.paymentOptions.deliveryDate,
                      disabledDates: { weekdays: [1, 7] },
                    }),
                  ]),
                  billing: {
                    title: 'cart.billing.paperTitle',
                    description: 'cart.billing.description',
                    right: useBillingContent(cart, deliveryInformation.billingAddress),
                    articleCode: linkedCart.description.articleCode,
                    productCode: linkedCart.description.productCode,
                  },
                })
              }
              return r
            },
            [],
          ) ?? []

        const pricingDetails = computed(() => {
          if (useWorkflow.pricings.some((pricing) => pricing.isValidating.value)) {
            return []
          }

          const pricingDetails = []

          const pricingCtr = useWorkflow.pricings.find(
            (pricing) => pricing.data.value?.productCode === ProductCode.CARTE_TICKET_RESTAURANT,
          )

          if (pricingCtr) {
            pricingDetails.push(
              {
                icon: 'card-send',
                label: 'cart.payment.pricingDetail.loadingsOrdered',
                value: pricingCtr.data.value?.pricing?.titlesCount ?? 0,
              },
              {
                icon: 'card-add',
                label: 'cart.payment.pricingDetail.cardsOrdered',
                value: pricingCtr.data.value?.pricing?.cardsCount ?? 0,
              },
            )
          }

          const pricingTr = useWorkflow.pricings.find(
            (pricing) => pricing.data.value?.productCode === ProductCode.TICKET_RESTAURANT,
          )

          if (pricingTr) {
            pricingDetails.push({
              icon: 'board',
              label: 'cart.payment.pricingDetail.titlesOrdered',
              value: pricingTr.data.value?.pricing?.titlesCount ?? 0,
            })
          }

          return pricingDetails
        })

        return {
          ...useWorkflow,
          dataProps,
          pricingDetails,
          showProduct: true,
          showMissingEmails: false,
        }
      },
    }
  },
}

export default step
