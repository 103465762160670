import type { AxiosError, AxiosInstance } from 'axios'
import { Result } from '@badrap/result'
import type { ApiAxiosError } from '../common/errors'
import type { Pagination } from '../common/types'
import { DeliveryPointsError } from './errors'
import type { DeliveryPoint, DeliveryPointItem, PaginationParams, DistributionPoint } from './types'
import urlReplacer from '@/services/utils/urlReplacer'
import type { ProductCode } from '@/variables/ProductCode'
import type { DeliveryPointType } from '@/variables/DeliveryPointType'

const baseUrl = 'api/:version/DeliveryPoints/:deliveryPointId'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  deliveryPointId: '',
})

export default (axiosInstance: AxiosInstance) => {
  const fetchDeliveryPoint = async (deliveryPointId: string | number) => {
    try {
      const result = await axiosInstance.get<DeliveryPoint>(`${getBaseUrl({ deliveryPointId })}`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new DeliveryPointsError(error as ApiAxiosError))
    }
  }

  const getDeliveryPointsListV4 = async (params: PaginationParams) => {
    try {
      const result = await axiosInstance.get<Pagination<DeliveryPointItem>>(`${getBaseUrl()}`, { params })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error
    }
  }

  const fetchLastDeliveryPoint = async (productCode: ProductCode, deliveryPointsType: DeliveryPointType) => {
    try {
      const result = await getDeliveryPointsListV4({
        pageSize: 1,
        page: 1,
        sort: 'Reference',
        sortDirection: 'DESC',
        productCode,
        deliveryPointsType,
      })
      return Result.ok(result.data.items && result.data.items.length > 0 ? result.data.items[0] : null)
    } catch (error) {
      return Result.err(error as AxiosError)
    }
  }

  const fetchDistributionPoints = async (productCode: ProductCode, deliveryPointId: number) => {
    try {
      const result = await axiosInstance.get<DistributionPoint[]>(
        `${getBaseUrl({ deliveryPointId })}/distributionPoints`,
        {
          params: { productCode },
        },
      )
      if (result.status === 204) {
        return Result.ok([])
      }
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new DeliveryPointsError(error as ApiAxiosError))
    }
  }

  return {
    fetchDeliveryPoint,
    getDeliveryPointsListV4,
    fetchLastDeliveryPoint,
    fetchDistributionPoints,
  }
}
