export default {
  cart: {
    title: 'Panier',
    header: {
      titleDetail: {
        mixed: 'Commande mixte',
        preOrder: 'Précommande',
        plasticless: 'Chargement et Création de comptes',
        ctr: 'Chargement et Création de cartes',
      },
      reference: 'Référence&nbsp;: <b>{reference}</b>',
      referenceMixed: {
        paper: 'Référence papier&nbsp;: <b>{reference}</b>',
        card: 'Référence carte&nbsp;: <b>{reference}</b>',
      },
      vintage: '<b>Millésime {year}</b>',
      vintageMixed: {
        paper: 'Papier-Millésime {year}',
        card: 'Carte-Millésime {year}',
      },
      date: 'Le&nbsp;: <b>{date}</b>',
      creator: 'Par&nbsp;: <b>{creator}</b>',
      channel: 'Via&nbsp;: <b>{channel}</b>',
      validity: 'Validité&nbsp;: <b>{month} mois</b>',
      tooltip: {
        default: 'Fin : {date}',
      },
    },
    referencePopin: {
      title: {
        default: 'Modifier la référence de votre commande',
        paper: 'Modifier la référence de votre commande papier',
        card: 'Modifier la référence de votre commande carte',
      },
      inputLabel: 'Référence de commande',
      errors: {
        required: 'La référence de commande est obligatoire',
        invalid: 'Référence invalide',
        maxLength: 'Maximun 50 caractères',
        restrictedCharacters: 'Les caractères point-virgule, & et # ne sont pas autorisés.',
      },
      success: {
        title: 'Référence modifiée',
        description: 'La référence de commande a été mise à jour',
      },
    },
    clearCart: {
      reimportFile: {
        title: 'Êtes-vous sûr de vouloir réimporter un fichier&nbsp;?',
        description: 'Votre panier sera supprimé et remplacé par votre import',
        button: 'Ré-importer',
      },
      importFile: {
        title: 'Êtes-vous sûr de vouloir importer un fichier&nbsp;?',
        description: 'Votre panier sera supprimé et remplacé par votre import',
        button: 'Importer',
      },
      addBeneficiary: {
        title: 'Êtes-vous sûr de vouloir ajouter un bénéficiaire&nbsp;?',
        description: 'Votre panier sera supprimé et remplacé par votre bénéficiaire',
        button: 'Ajouter un bénéficiaire',
      },
    },
    deleteCartItem: {
      default: {
        title: "Supprimer l'élément de votre panier",
        description: "L'élément sélectionné va être <b>supprimé</b> de votre panier.",
      },
      beneficiary: {
        title: 'Supprimer le bénéficiaire de votre panier',
        description: 'Le bénéficiaire sélectionné va être <b>supprimé</b> de votre panier.',
      },
      success: {
        title: 'Élément supprimé',
        description: 'L’élément à bien été retiré de votre panier',
      },
    },
    button: {
      importCsv: 'Importer CSV',
      reimportCsv: 'Réimporter un fichier',
      addBenef: 'Ajouter un bénéficiaire',
      goToPayment: 'Passer au paiement',
      seeMore: 'Voir +',
      seeLess: 'Voir -',
      addProduct: 'Compléter mon panier',
      next: 'Suivant',
    },
    tr: {
      emptyDescription: 'Vous n’avez pas encore de produit dans votre commande<br/>Ajoutez des bénéficiaires',
      anonymousEmptyDescription: 'Vous n’avez pas encore de produit dans votre commande',
    },
    ks: {
      emptyDescription: 'Vous n’avez pas encore de produit dans votre commande',
      messagePersonalized: 'Message personnalisé',
    },
    mixed: {
      title: 'Ticket Restaurant® carte et papier',
      table: {
        headers: {
          tooltip: {
            quantity:
              '<ul class="text-start"><li class="mb-1">Le nombre de titres est arrondi à l’entier supérieur, et reporté par défaut en chargement carte. Ex : 13 titres répartis à 50% carte / 50 % papier = 7 titres carte + 6 titres papier</li><li>Commande minimale requise de 3 titres pour 1 carnet papier.  Exemple : 4 titres répartis à 50% carte / 50 % papier = 4 titres carte + 0 titres papier</li></ul>',
          },
        },
      },
    },
    delivery: {
      title: 'Livraison',
      load: 'Chargement',
      loadCardTitle: 'Chargement des cartes',
      paperTitle: 'Livraison des titres papier',
      cardTitle: 'Livraison des cartes',
      description:
        'Les nouvelles cartes seront envoyées directement à l’adresse de vos bénéficiaires.<br/><br/><b class="text-primary">Expédition sous 24h à 48h</b>',
      atCompanyDescription:
        'Les nouvelles cartes seront envoyées directement à l’adresse de l’entreprise.<br/><br/><b class="text-primary">Dès la prise en compte de votre commande</b><br/><b>Livraison estimée&nbsp;: {date}</b>',

      kscDescription:
        "Les titres seront envoyés par e-mail à vos bénéficiaires dès la confirmation de la commande.<br/>Il ne sera pas possible d'annuler ou de rembourser cette commande une fois confirmée.",
      choice: {
        default: {
          earliestLabel: 'Livraison au plus tôt',
          earliestDescription1: 'Livraison à partir du {date}',
          earliestDescription2: 'Expédition sous 24h à 48h hors week-end et jours fériés',
          futureLabel: 'Livraison à une date ultérieure',
          futureInputLabel: 'Date de livraison',
        },
        dematerialized: {
          earliestLabel: 'Chargement au plus tôt',
          earliestDescription1: 'Chargement à partir du {date}',
          earliestDescription2: '',
          futureLabel: 'Chargement à une date ultérieure',
          futureInputLabel: 'Date de chargement',
        },
      },
    },
    billing: {
      title: 'Facturation',
      paperTitle: 'Facturation de la commande papier',
      cardTitle: 'Facturation de la commande carte',
      description:
        'Votre facture sera disponible dans votre rubrique factures et avoirs, 24h après la validation de votre commande.',
      isPfaDescription: "Votre commande fera l'objet de plusieurs factures selon le paramétrage de votre dossier",
    },
    basket: {
      showMore: 'Voir +',
      showLess: 'Voir -',
      success: {
        title: 'Panier mis à jour',
        editCartItem: "L'élément a bien été modifié",
      },
    },
    pricing: {
      default: {
        orderedAmount: 'Montant commandé',
        shippingFees: 'Frais de livraison',
        administrativeFees: 'Frais de gestion',
        cardsCreationFees: "Frais d'émission de cartes",
        otherFees: 'Autres frais',
        discount: 'Remise',
        total: 'Total',
        vat: 'TVA',
        totalWithVAT: 'Total',
      },
      mixed: {
        card: {
          orderedAmount: 'Commande carte',
          totalWithVAT: 'Total carte',
        },
        paper: {
          orderedAmount: 'Commande papier',
          totalWithVAT: 'Total papier',
        },
      },
      tooltip:
        'Les frais de la commande seront appliqués à l’étape suivante. Vous pourrez également saisir votre code promo.',
      otherFeesTooltip: "dont frais d'émission de cartes",
      download: 'Télécharger le récapitulatif',
    },
    import: {
      title: 'Import',
      download: {
        default: 'Télécharger le modèle',
        empty: 'Fichier sans bénéficiaires',
        withData: 'Fichier avec bénéficiaires',
      },
      settingsAction: {
        amount: 'Montant&nbsp;: <b>{amount}</b>',
        event: 'Événement&nbsp;: <b>{event}</b>',
        deliveryPoint: 'Livraison&nbsp;: <b>{deliveryPointType}</b>',
        deliveryPoint2: 'Livraison',
      },
      fields: {
        title: 'Aide',
        field: 'Colonne “{name}”',
      },
      errors: {
        title: 'Liste des erreurs',
        count: '1 erreur | {count} erreurs',
        listTitle: 'Liste des lignes en erreur',
        codeError: 'Code d’erreur',
        settingKscNotConfigured: "L'évenement et/ou le montant ne sont pas configurés",
        settingTrNotConfigured: 'Le montant et/ou la livraison ne sont pas configurés',
        settingCtrvNotConfigured: "Le montant n'est pas configuré",
        wrongFormat: 'Seuls les fichiers .csv sont acceptés.',
        fileNotExist: "Le fichier n'existe pas",
      },
      loading: {
        status: {
          Default: 'Transfert du fichier en cours ...',
          UploadingFile: 'Analyse du fichier en cours ...',
          WaitingIntegrationStart: 'Enregistrement des bénéficiaires en cours ...',
          FileIntegration: 'Contrôle des éléments de la commande en cours ...',
          FileVerifications: 'Enregistrement de la commande en cours ...',
          BeneficiaryArbitral: 'Dédoublonnage des bénéficiaires en cours ...',
          WaitingUserArbitralValidation: 'Dédoublonnage des bénéficiaires en cours ...',
          BeneficiariesIntegration: 'Ajout des bénéficiaires ...',
          PricingGeneration: 'Calcul du montant ...',
          MixedOrderSplit: 'Division de la commande...',
          WaitingUserConfirmation: 'Finalisation de la commande...',
          End: 'End',
        },
      },
    },
    dedupe: {
      title: 'Doublons potentiels',
      duplicate: 'Doublon',
      description:
        '<p>Nous avons repéré plusieurs doublons potentiels. Vous pouvez sélectionner l’une des deux actions&nbsp;:</p><p><b>Conserver</b> ou <b>remplacer</b> votre  bénéficiaire importé.</p>',
      imported: 'Nouvellement importé',
      existing: 'Déjà existant',
      addAndKeep: 'Ajouter et conserver',
      replace: 'Remplacer',
      addAndKeepTooltip: "Si vous choisissez d'ajouter et conserver, un nouveau collaborateur sera alors créé",
      replaceTooltip:
        'Si vous choisissez de remplacer un collaborateur, les anciennes données qui lui sont liées seront remplacées par les nouvelles issues de votre fichier',
      by: 'Par',
      importTooltip:
        ' Si vous avez effectué une erreur sur un nouveau bénéficiaire, vous devrez réimporter votre fichier avec les bonnes informations pour le corriger',
      button: {
        import: 'Importer à nouveau',
        addAndKeep: 'Ajouter et conserver',
        replace: 'Remplacer',
      },
    },
    payment: {
      discountCode: 'Code promo',
      button: {
        discountCode: 'Valider code promo',
        confirm: 'Finaliser ma commande',
      },
      pricingDetail: {
        titlesOrdered: 'Titres commandés',
        loadingsOrdered: 'Chargements commandés',
        cardsOrdered: 'Cartes commandées',
      },
      errors: {
        noItems: 'Panier vide',
        terms: 'Veuillez accepter les conditions générales de vente',
        successDiscountCode: 'Le code promo {code} a bien été pris en compte. La réduction a bien été appliquée',
        invalidDiscountCode: "Votre code promo n'a pas pu être appliqué. Merci de vérifier les conditions de l'offre",
      },
      confirmCardsActivation: {
        title: 'Activation des cartes',
        description:
          'Les cartes de la commandes seront à activer sur la page de détail de commande de votre Espace Client.',
        confirmationLabel:
          'Je confirme être en mesure de <b>les activer sur l’Espace Client</b> avant de les distribuer à mes collaborateurs.',
      },
    },
    confirmation: {
      title: 'Merci pour votre commande {label}',
      description: 'Elle apparaitra dans votre historique de commande d’ici quelques minutes.',
      passToDebit: {
        title: 'La prochaine fois allez encore plus vite,<br />Passez au prélèvement automatique&nbsp;!',
        description: 'En activant le prélèvement automatique, gagnez du temps lors que de votre prochaine commande.',
        button: 'Passer au prélèvement',
      },
      reminderTransfer: {
        title: 'Pensez à réaliser votre virement&nbsp;!',
        description:
          'Dès que votre virement sera enregistré vous serez informé par email dans les plus brefs délais de la validation de votre commande.',
      },
      default: {
        title: 'Votre commande a bien été prise en compte&nbsp;!',
        description: 'Vous la verrez dans votre historique de commande dans les prochains jours.',
      },
      activateAccount: {
        title: 'Plus qu’une étape pour suivre votre commande',
        description:
          '<b>Activez votre compte</b> pour accéder à toutes les fonctionnalités de votre Espace Client&nbsp;! ',
        button: 'Activer mon compte',
      },
      passToCard: {
        title: 'Commandez des cartes Ticket Restaurant',
        description:
          'Paiement au centime près, avec ou sans contact, sur place, ou à emporter, choisissez la liberté&nbsp;!',
        button: 'Passer à la carte',
      },
      activateCard: {
        title: 'Activation des cartes',
        description:
          'Vous devez activer les cartes avant de les distribuer. Dès réception de votre commande, rendez-vous dans votre Espace Client, rubrique «&nbsp;Suivi de commande&nbsp;», dans le détail de votre commande, cliquez sur le bouton «&nbsp;Activer mes cartes&nbsp;»',
        plBenef:
          "Vos bénéficiaires recevront un code par email à renseigner dans leur espace MyEdenred leur permettant d'activer leurs cartes",
      },
    },
    errors: {
      title: 'Erreur Panier',
      cartNotFound: 'Le panier est introuvable',
      articleCodeNotFound: "L'article est introuvable",
      productNotFound: 'Le produit est introuvable',
      workflowNotFound: 'La configuration du panier est introuvable',
      stepNotFound: "L'étape est introuvable",
      moduleNotFound: 'Le module est introuvable',
      termsNotFound: 'Les conditions générales de vente sont introuvable',
      valueOfTitleLoadFail: 'Impossible de charger la configuration des montants',
      stepNotAllowed: 'Accès interdit',
      editCartItem: "Impossible de modifier l'élément du panier",
      InvalidDiscountCode: "Votre code promo n'a pas pu être appliqué. Merci de vérifier les conditions de l'offre.",
      BeneficiaryAlreadyExist: 'Le bénéficiaire existe déjà dans le panier',
    },
    modules: {
      saveDefaultParams: 'Enregistrer ces paramètres pour mes prochaines commandes',
      addItem: {
        title: 'Ajouter un produit',
        editTitle: 'Modifier le produit',
        action: 'Ajouter au panier',
        editAction: 'Modifier',
        itemTypeTabs: {
          title: 'Choisir des carnets ou des tickets',
          board: 'Ticket à l’unité',
        },
        events: {
          title: 'Un évènement en particulier ?',
          tooltip:
            'Evénements ACOSS exonérés de cotisations et de charges sociales à hauteur de {limitExo} par événement, par bénéficiaire et par an',
          link: 'Voir la réglementation sur l’exonération fiscale des titres-cadeaux',
          errors: {
            required: "L'évènement est requis",
          },
          multi: {
            toggle: 'Plusieurs événements',
            tooltip: "Permet d'appliquer plusieurs événements par bénéficiaire dans une seule commande",
            description:
              'A l\'étape suivante, vous pourrez télécharger un fichier de commande. Dans la colonne "CODE EVENEMENT", renseignez un évènement ACOSS pour chaque bénéficiaire. Vous pouvez mettre un code évènement différent pour chaque bénéficiaire.<br /><br />Exemple : 56 pour Noël des salariés, 52 pour Naissance, 0 pour aucun événement ACOSS...',
          },
        },
        quantity: {
          Booklet: 'Nombre de carnets',
          Board: 'Nombre de tickets',
          Bulk: 'Nombre de tickets',
          Card: 'Nombre de cartes',
          RechargeableCard: 'Nombre de cartes',
          Virtual: 'Nombre de cartes',
          pockets: 'Ajouter des pochettes',
          errors: {
            required: 'Le champs est requis',
            BookletBetween: 'Saisissez un nombre de carnet(s) entre {min} et {max}',
            CardBetween: 'Saisissez un nombre de carte(s) entre {min} et {max}',
            BoardBetween: 'Saisissez un nombre de ticket(s) entre {min} et {max}',
            BulkBetween: 'Saisissez un nombre de ticket(s) entre {min} et {max}',
          },
        },
        pocketsQuantity: {
          errors: {
            maxValue: 'La quantité maximum est de {max}',
          },
        },
        amount: {
          Booklet: 'Montant par carnet',
          Board: 'Montant par ticket',
          Bulk: 'Montant par ticket',
          Card: 'Montant par carte',
          RechargeableCard: 'Montant par carte',
          Dematerialized: 'Montant par bénéficiaire',
          recommended: {
            title: 'Le plus choisi',
            tooltip:
              "Sous réserve du respect des conditions cumulatives fixées par la circulaire ACOSS n°2011-0000024, le plafond est de {limitExo} par événement et par salarié pour l'année {year}",
          },
          decompositionLabel: 'Choisir ma préférence de décomposition',
          decompositionTooltip:
            'Il se peut que votre choix ne puisse pas être respecté pour des raisons techniques (minimum {min} titres papier par carnet)',
          decomposition: 'Décomposition',
          errors: {
            required: 'Le montant est requis',
            integer: 'Veuillez saisir un entier',
            between: 'Saisissez un montant entre {min} et {max}',
            modulo: "Le montant commandé doit être un multiple de 5€, à l'exception de {limitExo}",
          },
          passExo: {
            title: 'Montant supérieur au plafond d’exonération',
            description:
              'Lorsque le montant de la dotation est supérieur à {limitExo} par bénéficiaire (ou enfant du bénéficiaire), la totalité est soumise aux cotisations et charges sociales, dès le premier euro. Vous pouvez commander Kadeos Culture pour bénéficier toute l’année d’une exonération à 100%.',
            link: 'Commander Kadeos Culture',
          },
        },
        personalizedMessage: {
          Booklet: 'Message personnalisé sur les tickets',
          Board: 'Message personnalisé sur les tickets',
          Bulk: 'Message personnalisé sur les tickets',
          Card: 'Message personnalisé sur les cartes',
          tooltip: "Votre message personnalisé s'affichera à la place de votre raison sociale sur la carte",
          line1: 'Ligne 1',
          line2: 'Ligne 2',
          line3: 'Ligne 3',
          errors: {
            maxLength: 'Le message ne peut pas dépasser les {max} caractères',
            noSpecialChars: 'Les caractères suivants sont interdits ,;:«»( )_‘. &{}°/\\*',
          },
        },
        deliveryPoint: {
          placeholder: 'Point de livraison',
          distributionPoint: 'Point de distribution',
          errors: {
            deliveryPoint: {
              required: 'Le point de livraison est requis',
            },
            distributionPoint: {
              required: 'Le point de distribution est requis',
            },
          },
        },
        summary: {
          Booklet:
            'Total : 0 carnet de {price}  =  {total} | Total : {count} carnet de {price}  =  {total} | Total : {count} carnets de {price}  =  {total}',
          Board:
            'Total : 0 ticket de {price}  =  {total} | Total : {count} ticket de {price}  =  {total} | Total : {count} tickets de {price}  =  {total}',
          Bulk: 'Total : 0 ticket de {price}  =  {total} | Total : {count} ticket de {price}  =  {total} | Total : {count} tickets de {price}  =  {total}',
          Card: 'Total : 0 carte de {price}  =  {total} | Total : {count} carte de {price}  =  {total} | Total : {count} cartes de {price}  =  {total}',
        },
      },
      settings: {
        title: 'Paramètres',
        save: 'Enregistrer',
        valueOfTitle: {
          title: 'Valeur du titre',
          tooltip: 'Montant journalier attribué par {product}.<br />Montant maximum&nbsp;: {price}',
          isMultiValueOfTitle: 'Plusieurs valeurs',
          multiValueOfTitle:
            'A l\'étape suivante, vous pourrez télécharger un fichier de commande. Dans la colonne "VALEUR DU TITRE EN CENTIME", renseignez le montant de la valeur du titre pour chaque bénéficiaire. Vous devrez aussi compléter la colonne "PART PATRONALE EN POURCENTAGE" en y renseignant le pourcentage de part patronale pour chaque bénéficiaire.<br /><br/>Exemple : Je dois commander des titres à 8,50€ pris en charge à 51,40% je renseigne 850 dans la colonne "VALEUR DU TITRE EN CENTIME" et 51.40 dans la colonne “PART PATRONALE EN POURCENTAGE”',
          toDefine: '<span class="text-danger">À définir</span>',
        },
        deliveryPoint: {
          title: 'Point de livraison',
          titleCard: 'Livraison des cartes',
          titlePaper: 'Livraison des titres papier',
          toDefine: '<span class="text-danger">À définir</span>',
          multiDistributionPoint: 'Plusieurs points de distribution',
          beneficiary: {
            title: 'Chez le bénéficiaire',
            placeholder: 'Transporteur',
            multiDeliveryPoint: 'Plusieurs points de rattachement',
            description: 'Les nouvelles cartes seront livrées directement chez les bénéficiaires',
            descriptionPaper: 'Vos titres seront livrées directement chez les bénéficiaires',
          },
          company: {
            title: "À l'entreprise",
            placeholder: 'Adresse de livraison',
            multiDeliveryPoint: 'Plusieurs adresses',
          },
          multiDeliveryPointDescription: 'Les points de livraisons seront à renseigner lors de votre import',
          ctrv: {
            pfaDescription:
              'Votre compte est paramétré avec plusieurs points de facturation. Les factures seront générées en fonction de vos points de distribution à renseigner à l’étape suivante.',
            description:
              'Si vos bénéficiaires ne possèdent pas de compte MyEdenred, ils seront invités par email à le créer. Les titres seront directement chargés sur les comptes carte Ticket Restaurant® des bénéficiaires.',
          },
        },
        amount: {
          isMulti: 'Plusieurs montants',
          tooltip: 'Permet de commander un montant différent pour chaque bénéficiaire',
          multiAmount:
            'Vous personnaliserez le montant de vos titres pour chaque bénéficiaire directement dans votre import',
          errors: {
            required: 'Le montant est requis',
            minValue: 'Le montant minimum est de {min}',
            maxValue: 'Le montant maximum est de {max}',
          },
        },
      },
      addItemBeneficiary: {
        itemForm: {
          quantity: {
            label: 'Quantité',
            errors: {
              required: 'La quantité est requise',
              between: 'Saisissez une quantité entre {min} et {max}',
              integer: 'Veuillez saisir un entier',
            },
          },
          amountTitle: 'Montant par titre',
          deliveryPointTitle: 'Choisir mon point de livraison',
        },
      },
      beneficiary: {
        title: 'Ajouter des bénéficiaires',
        addBeneficiary: 'Existant',
        new: 'Nouveau',
        createBeneficiary: 'Créer un bénéficiaire',
        addSuccess: 'Le bénéficiaire a bien été ajouté au panier',
        createSuccess: 'Le bénéficiaire a bien été créé et ajouté au panier',
        editSuccess: 'Le bénéficiaire a bien été modifié et ajouté au panier',
        list: 'Liste de vos bénéficiaires',
        add: 'Ajouter tous les bénéficiaires',
        seeCart: 'Voir mon panier',
        success: {
          default: 'Tout les bénéficiaires ont été ajouté au panier',
          withError:
            "Une erreur est survenue. Vérifiez votre panier pour voir la liste des autres bénéficiaires ajoutés. | Le bénéficiaire {names} n'a pas pu être ajouté. Vérifiez votre panier pour voir la liste des autres bénéficiaires ajoutés. | Les bénéficiaires {names} et {lastName} n'ont pas pu être ajouté. Vérifiez votre panier pour voir la liste des autres bénéficiaires ajoutés.",
        },
      },
      importOrAdd: {
        import: {
          title: 'Import de fichier',
          description: 'Importez tous vos bénéficiaires à partir d’un fichier CSV.',
          button: 'Importer un fichier',
        },
        add: {
          title: 'Ajout manuel',
          description: 'Ajoutez ou créez vos bénéficiaires manuellement.',
          button: 'Ajouter manuellement',
        },
      },
    },
    missingEmails: {
      title: 'Email manquant | Emails manquants ({count})',
      subTitle: 'Complétez les emails de vos bénéficiaires',
      description:
        "Certains bénéficiaires n'ont pas d'adresse email renseignée, vous pouvez les compléter manuellement ou réimporter votre fichier.",
      help: {
        title: 'Communiquez-nous les adresses emails de vos bénéficiaires pour gagner du temps !',
        description:
          'Laissez-nous accompagner vos bénéficiaires dans la gestion de leur carte et de leur compte au quotidien et ne faites plus le passe-plat entre eux et nous !<br /><br />Grâce à leurs adresses emails, nous pouvons notamment :',
        bulletPoints: {
          first: 'Les aider en cas de non-réception de leur carte,',
          second:
            'Les informer du chargement de leur compte ou d’une problématique éventuelle sur leur carte ou leur compte,',
          third:
            'Les tenir au courant des évolutions réglementaires telle que l’évolution du plafond de dépense quotidien.',
        },
        bottomText: 'Aucune utilisation commerciale n’en sera faite sans l’accord de vos bénéficiaires.',
      },
      table: {
        columns: {
          beneficiary: 'Bénéficiaire',
          email: 'Email professionnel ou personnel',
        },
        registrationNumber: '<b>Matricule&nbsp;:</b>&nbsp;{registrationNumber}',
      },
      import: {
        title: 'Réimporter votre fichier de commande',
        description:
          'Ajouter ou renseigner la colonne “Email” dans votre fichier pour y renseigner les adresses de vos bénéficiaires',
        button: 'Réimporter',
      },
      rules: {
        error: 'Erreur',
        atLeastOne: 'Au moins un email est requis',
        email: 'Au moins un email a un format invalide',
      },
    },
  },
}
