import { computed } from 'vue'
import { useBillingContent, useDelivery } from '@/store/cart/cartWorkflows/common/steps/payment'
import payment from '@/store/cart/cartWorkflows/univers/TR/common/steps/payment'
import type { DeliveryProp, PaymentWorkflow, PaymentWorkflowRecord } from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'

const step: StepBuilder<PaymentWorkflow, PaymentWorkflowRecord> = {
  ...payment,
  useStep(cart, workflow, record, useAction) {
    const useStep = payment.useStep(cart, workflow, record, useAction)
    return {
      ...useStep,
      useWorkflow() {
        const useWorkflow = useStep.useWorkflow()
        const deliveryInformation = record.deliveryInformations[cart.remote.id]

        const deliveries = computed<DeliveryProp[]>(() => {
          const _deliveries: DeliveryProp[] = [
            useDelivery(cart.remote, deliveryInformation, {
              title: 'cart.delivery.loadCardTitle',
              canChangeDate: true,
              type: 'dematerialized',
              productCode: cart.remote.description.productCode,
              articleCode: cart.remote.description.articleCode,
              deliveryDate: cart.remote.paymentOptions.deliveryDate,
            }),
          ]

          if (useWorkflow.pricings.some((pricing) => pricing.isValidating.value)) {
            return _deliveries
          }

          const pricing = useWorkflow.pricings.find(
            (pricing) => pricing.data.value?.productCode === cart.meta.productCode,
          )

          const cardsCount = pricing?.data.value?.pricing?.cardsCount ?? 0
          const titlesCount = pricing?.data.value?.pricing?.titlesCount ?? 0

          if (cardsCount > 0 || (cardsCount === 0 && titlesCount === 0)) {
            _deliveries.push(
              useDelivery(cart.remote, deliveryInformation, {
                title: 'cart.delivery.cardTitle',
                canChangeDate: false,
                type: 'default',
                description:
                  cart.remote.options.isHomeDelivery || deliveryInformation.deliveryPoint?.isHomeDelivery
                    ? 'cart.delivery.description'
                    : 'cart.delivery.atCompanyDescription',
                productCode: cart.remote.description.productCode,
                articleCode: cart.remote.description.articleCode,
                deliveryDate: null,
              }),
            )
          }

          return _deliveries
        })

        return {
          ...useWorkflow,
          dataProps: [
            {
              deliveries,
              billing: {
                title: 'cart.billing.title',
                description: 'cart.billing.description',
                right: deliveryInformation.billingAddress
                  ? useBillingContent(cart, deliveryInformation.billingAddress)
                  : null,
                productCode: cart.remote.description.productCode,
                articleCode: cart.remote.description.articleCode,
              },
            },
          ],
          pricingDetails: computed(() => {
            if (useWorkflow.pricings.some((pricing) => pricing.isValidating.value)) {
              return []
            }

            const pricing = useWorkflow.pricings.find(
              (pricing) => pricing.data.value?.productCode === cart.meta.productCode,
            )

            return [
              {
                icon: 'card-send',
                label: 'cart.payment.pricingDetail.loadingsOrdered',
                value: pricing?.data.value?.pricing?.titlesCount ?? 0,
              },
              {
                icon: 'card-add',
                label: 'cart.payment.pricingDetail.cardsOrdered',
                value: pricing?.data.value?.pricing?.cardsCount ?? 0,
              },
            ]
          }),
        }
      },
    }
  },
}

export default step
