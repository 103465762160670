import { PriceKey, type Pricing, type PricingSummary } from '@/services/carts/types'
import type { ProductCode } from '@/variables/ProductCode'

export interface Price {
  id: PriceKey
  label: string
  amount: number
  type: PriceType
  suffix?: string
  tooltip?: string
}

export enum PriceType {
  Default = 'default',
  Help = 'help',
  SubTotal = 'subtotal',
  Total = 'total',
}

export interface PricingData {
  readonly: boolean
  productCode: ProductCode
  prices: Price[]
  pricing?: Pricing
}

export const pricingTemplate: Price[] = [
  {
    id: PriceKey.orderedAmount,
    label: 'cart.pricing.default.orderedAmount',
    amount: 0,
    type: PriceType.Default,
  },
  {
    id: PriceKey.discount,
    label: 'cart.pricing.default.discount',
    amount: 0,
    type: PriceType.Default,
  },
  {
    id: PriceKey.shippingFees,
    label: 'cart.pricing.default.shippingFees',
    amount: 0,
    type: PriceType.Default,
  },
  {
    id: PriceKey.administrativeFees,
    label: 'cart.pricing.default.administrativeFees',
    amount: 0,
    type: PriceType.Default,
  },
  {
    id: PriceKey.otherFees,
    label: 'cart.pricing.default.otherFees',
    amount: 0,
    type: PriceType.Help,
    tooltip: 'cart.pricing.otherFeesTooltip',
  },
  {
    id: PriceKey.total,
    label: 'cart.pricing.default.total',
    amount: 0,
    type: PriceType.SubTotal,
  },
  {
    id: PriceKey.vat,
    label: 'cart.pricing.default.vat',
    amount: 0,
    type: PriceType.Default,
  },
  {
    id: PriceKey.totalWithVAT,
    label: 'cart.pricing.default.totalWithVAT',
    amount: 0,
    type: PriceType.Total,
    suffix: 'TTC',
  },
]

export const orderPrice: Record<PriceKey, number> = {
  [PriceKey.orderedAmount]: 0,
  [PriceKey.discount]: 1,
  [PriceKey.discountVE]: 2,
  [PriceKey.administrativeFees]: 3,
  [PriceKey.shippingFees]: 4,
  [PriceKey.cardsCreationFees]: 5,
  [PriceKey.otherFees]: 6,
  [PriceKey.total]: 7,
  [PriceKey.vat]: 8,
  [PriceKey.totalWithVAT]: 9,
  [PriceKey.titlesCount]: 100,
  [PriceKey.cardsCount]: 101,
}

export function fetchPrices(pricing: Pricing): Price[] {
  const prices = pricingTemplate
    .reduce<Price[]>((r, priceTemplate) => {
      if (priceTemplate.id === PriceKey.orderedAmount) {
        const amount = pricing[PriceKey.orderedAmount] + pricing[PriceKey.discountVE]
        r.push({ ...priceTemplate, amount })
      } else if (priceTemplate.id === PriceKey.discount) {
        const amount = -pricing[PriceKey.discountVE]

        if (amount !== 0) {
          r.push({ ...priceTemplate, amount })
        }
      } else if (priceTemplate.id === PriceKey.otherFees) {
        const amount = pricing[PriceKey.otherFees]
        r.push({ ...priceTemplate, amount })
      } else {
        const amount = pricing[priceTemplate.id]
        r.push({ ...priceTemplate, amount })
      }

      return r
    }, [])
    .sort((a, b) => orderPrice[a.id] - orderPrice[b.id])

  return prices
}

export function fetchPricesWithSummary(pricing: PricingSummary): Price[] {
  const priceTotal = pricingTemplate.find((pricing) => pricing.id === PriceKey.total)

  if (priceTotal == null) {
    return []
  }

  return [{ ...priceTotal, amount: pricing.total }]
}
