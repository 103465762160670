import { Result } from '@badrap/result'
import { computed, ref } from 'vue'
import type { IResponse } from 'swrv/dist/types'
import { usePricing } from '../usePricing'
import useSWRV from '@/utils/useSwrv'
import { moduleConfig as discountCodeConfig } from '@/components/Cart/Module/DiscountCode/workflow'
import { stepConfig as basketStepConfig } from '@/components/Cart/Step/Basket/workflow'
import { stepConfig as confirmationConfig } from '@/components/Cart/Step/Confirmation/workflow'
import {
  type Content,
  type PaymentWorkflow,
  stepConfig,
  type CommonPaymentWorkflow,
  type UseDeliveryConfig,
  type PaymentMeanProps,
  type DeliveryProp,
  type PaymentWorkflowRecord,
} from '@/components/Cart/Step/Payment/workflow'
import apiV4 from '@/services/apiV4'
import { FrontCartStatus, type CartWorkflow, type RemoteCart, type StepBuilder } from '@/store/cart/model'
import { ActionType, type Action, type ActionConstructor } from '@/types/Action'
import { PaymentMean, type DeliveryInformations } from '@/services/carts/types'
import { CartStatus, type Cart } from '@/services/carts/typesV2'
import router from '@/router'
import { formPostPayment } from '@/helpers/formPostPayment'
import { mapAddressToHtml, type Address } from '@/types/Address'
import type { PricingData } from '@/types/Price'
import { fetchPaymentPermission } from '@/utils/paymentPermission'
import { ArticleCode } from '@/variables/ArticleCode'
import { accessor } from '@/store'
import { useCampaignStore } from '@/store/campaign'
import { useStore as useCartStore } from '@/store/cart/store'
import { ProductCode } from '@/variables/ProductCode'
import { useModalStore } from '@/store/modal'
import { formatDate } from '@/utils/date'
import { DeliveryChoiceSelectType } from '@/types/CartDelivery'

export function useDelivery(
  cart: Cart,
  deliveryInformations: DeliveryInformations,
  config: UseDeliveryConfig,
): DeliveryProp {
  const date = config.deliveryDate ?? deliveryInformations.datesEstimation.earliestDeliveryDate
  const deliveryDate = ref(date ? new Date(date) : null)
  let right: Content | null = null

  if (config.type !== 'dematerialized') {
    if (cart.options.isPlasticless) {
      right = {
        title: 'infoBlock.plasticless.title',
        description: 'infoBlock.plasticless.description',
      }
    } else if (cart.options.isHomeDelivery || deliveryInformations.deliveryPoint?.isHomeDelivery) {
      right = {
        title: 'infoBlock.deliveryPoint.title',
        description: 'infoBlock.deliveryPoint.description',
      }
    } else if (deliveryInformations.deliveryPoint && !deliveryInformations.deliveryPoint.isHomeDelivery) {
      right = {
        title: 'infoBlock.deliveryPoint.title',
        description: mapAddressToHtml(deliveryInformations.deliveryPoint.address),
      }
    }
  }

  const choiceType = ref(
    cart.paymentOptions.deliveryDate ? DeliveryChoiceSelectType.Future : DeliveryChoiceSelectType.Earliest,
  )

  return {
    title: config.title,
    type: config.type,
    canChangeDate: config.canChangeDate,
    choiceType,
    description: config.description,
    productCode: config.productCode,
    articleCode: config.articleCode,
    disabledDates: config.disabledDates,
    deliveryDate,
    deliveryEstimation: deliveryInformations.datesEstimation,
    right,
    submit: async () => {
      if (config.canChangeDate && choiceType.value === DeliveryChoiceSelectType.Future) {
        const result = await apiV4.carts.patchCart(cart.id, {
          deliveryDate: formatDate(deliveryDate.value, 'yyyy-MM-dd'),
          reference: cart.description.orderReference,
          discountCode: cart.paymentOptions.discountCode,
          isHomeDelivery: cart.options.isHomeDelivery,
        })

        if (result.isErr) {
          return Result.err(result.error)
        }
      }
      return Result.ok(true)
    },
  }
}

export function useBillingContent(cart: RemoteCart, address: Address | null) {
  if (cart.meta.isPFA) {
    return {
      title: 'infoBlock.billingPoint.title',
      description: 'cart.billing.isPfaDescription',
    }
  }

  if (address) {
    return {
      title: 'infoBlock.billingPoint.title',
      description: mapAddressToHtml(address),
    }
  }
  return null
}

export const commonPaymentWorkflow: (
  cart: RemoteCart,
  workflow: CartWorkflow,
  record: PaymentWorkflowRecord,
  pricings: IResponse<PricingData>[],
  useAction: (actionConstructor: ActionConstructor) => Action,
) => CommonPaymentWorkflow = (cart, _workflow, record, pricings, useAction) => {
  const terms = ref(false)
  const discountCode = ref<string | null>(cart.remote.paymentOptions.discountCode)
  const campaignStore = useCampaignStore()
  const cartStore = useCartStore()

  const paymentMeanProps = useSWRV<PaymentMeanProps>(
    () =>
      pricings.find((pricing) => pricing.isValidating.value)
        ? null
        : `carts/paymentMeans/${cart.remote.id}?productCode=${pricings
            .map((pricing) => pricing.data.value?.productCode)
            .join(',')}`,
    async () => {
      const paymentMean: PaymentMean | null = null
      const paymentMeans = await apiV4.carts.fetchPaymentMeans(cart.remote.id)

      if (paymentMeans.includes(PaymentMean.VirementImmediat)) {
        return { paymentMean: PaymentMean.VirementImmediat, paymentMeans }
      } else if (paymentMeans.length > 0) {
        return { paymentMean: paymentMeans[0], paymentMeans }
      }

      return { paymentMean, paymentMeans }
    },
  )

  return {
    showHowTo: true,
    showProduct: false,
    showDownloadSummary: false,
    pricings,
    pricingDetails: computed(() => []),
    discountCode,
    prevAction: useAction({
      name: 'back',
      id: basketStepConfig.id,
      type: ActionType.Step,
      refresh: false,
    }),
    discountAction: useAction({
      name: 'cart.payment.button.discountCode',
      id: 'discount-action',
      type: ActionType.Default,
      refresh: true,
      async execute() {
        const result = await apiV4.carts.patchCart(cart.remote.id, {
          discountCode: discountCode.value,
          reference: cart.remote.description.orderReference,
          deliveryDate: cart.remote.paymentOptions.deliveryDate,
          isHomeDelivery: cart.remote.options.isHomeDelivery,
        })

        if (result.isErr) {
          return Result.err(result.error)
        }
        return Result.ok(true)
      },
    }),
    discountModalAction:
      campaignStore.campaign?.modal &&
      campaignStore.campaign.discountCode &&
      campaignStore.campaign.discountCode !== cart.remote.paymentOptions.discountCode
        ? useAction({
            name: discountCodeConfig.id,
            id: discountCodeConfig.id,
            type: ActionType.Popin,
            refresh: false,
            payload: {
              modal: campaignStore.campaign.modal,
              discountCode: campaignStore.campaign.discountCode,
            },
          })
        : null,
    downloadSummaryAction: useAction({
      name: 'cart.pricing.download',
      id: 'download-summary-action',
      icon: 'document-download',
      type: ActionType.Default,
      refresh: false,
      async execute() {
        await apiV4.carts.fetchSummaryPdf(cart.remote.id)

        return Result.ok(true)
      },
    }),
    payAction: (submits, fetchPaymentToken) => {
      const action = useAction({
        name: 'cart.payment.button.confirm',
        id: confirmationConfig.id,
        type: ActionType.Default,
        refresh: false,
        async execute() {
          if (record.totalItems === 0) {
            return Result.err(new Error('cart.payment.errors.noItems'))
          }

          if (!terms.value) {
            return Result.err(new Error('cart.payment.errors.terms'))
          }

          const results = await Promise.all(submits.map((submit) => submit()))
          const deliveryErrorResult = results.find((result) => result.isErr)

          if (deliveryErrorResult?.isErr === true) {
            return Result.err(deliveryErrorResult.error)
          }

          if (paymentMeanProps.data.value == null) {
            return Result.err(new Error('Payment Block not load'))
          }

          if (
            cart.meta.productCode === ProductCode.CARTE_KADEOS &&
            (cart.meta.articleCode === ArticleCode.CARTE_KADEOS_ZENITH_CE ||
              cart.meta.articleCode === ArticleCode.CARTE_KADEOS_ZENITH_DE ||
              cart.meta.articleCode === ArticleCode.CARTE_KADEOS_CULTURE) &&
            !cart.meta.isHomeDelivery
          ) {
            const modalStore = useModalStore()
            const confirmCardsActivation = await new Promise<boolean>((resolve) => {
              modalStore.push('sendToForm', () => import('@/components/popins/ConfirmCardsActivation.vue'), {
                id: 'confirmCardsActivation',
                cancel: () => resolve(false),
                confirm: () => resolve(true),
              })
            })

            if (!confirmCardsActivation) {
              return Result.ok(false)
            }
          }

          let paymentToken: string | null = null
          const { paymentMean } = paymentMeanProps.data.value

          if (paymentMeanProps.data.value.paymentMean === PaymentMean.CarteBancaire) {
            const result = await fetchPaymentToken()

            if (result.isOk) {
              paymentToken = result.value
            } else {
              return Result.err(result.error)
            }
          }

          if (paymentMean == null || (paymentMean === PaymentMean.CarteBancaire && !paymentToken)) {
            return Result.err(new Error('Missing Payment Token'))
          }

          cart.status = FrontCartStatus.Paying
          cartStore.setCart(cart)

          const result = await apiV4.carts.postPayments(cart.remote.id, {
            paymentMean,
            paymentToken: paymentToken ?? undefined,
            successRedirectUrl: new URL(`/panier/l/${cart.localId}/${confirmationConfig.path}`, window.location.origin)
              .href,
            cancelRedirectUrl: new URL(
              `/panier/l/${cart.localId}/${stepConfig.path}?cancel=true`,
              window.location.origin,
            ).href,
          })

          if (result.isErr) {
            return Result.err(result.error)
          }

          if (paymentMean === PaymentMean.CarteBancaire) {
            formPostPayment(result.value)
          } else if (paymentMean === PaymentMean.VirementImmediat) {
            window.location.assign(result.value.redirectionUrl)
          } else if (!result.value.redirectionUrl) {
            await router.push(`/panier/l/${cart.localId}/${confirmationConfig.path}`)
          }

          return Result.ok(true)
        },
      })

      const paymentPermission = fetchPaymentPermission(
        cart.meta.productCode,
        cart.meta.articleCode ?? ArticleCode.NO_PRODUCT,
        cart.meta.isNominative,
        cart.meta.isMixed,
      )
      if (!paymentPermission || !accessor.session.hasPermission(paymentPermission)) {
        action.disabled.value = true
      }
      return action
    },
    paymentMeanProps,
    terms,
  }
}

const step: StepBuilder<PaymentWorkflow, PaymentWorkflowRecord> = {
  config: stepConfig,
  async prepareStep(cart) {
    const result = await apiV4.carts.fetchDeliveryInformations(cart.remote.id)
    if (result.isErr) {
      return Result.err(result.error)
    }

    const totalItemsResult = await apiV4.carts.headItems(cart.remote.id)
    if (totalItemsResult.isErr) {
      return Result.err(totalItemsResult.error)
    }

    return Result.ok({
      deliveryInformations: { [cart.remote.id]: result.value },
      totalItems: totalItemsResult.value,
    })
  },
  isAllowed(cart) {
    return cart.remote.status === CartStatus.Opened
  },
  useStep(cart, workflow, record, useAction) {
    return {
      config: this.config,
      component: () => import('@/components/Cart/Step/Payment/index.vue'),
      useWorkflow() {
        const deliveryInformation = record.deliveryInformations[cart.remote.id]
        const commonWorkflow = commonPaymentWorkflow(cart, workflow, record, [usePricing(cart)], useAction)
        return {
          ...commonWorkflow,
          dataProps: [
            {
              deliveries: computed(() => [
                useDelivery(cart.remote, deliveryInformation, {
                  title: 'cart.delivery.paperTitle',
                  type: 'default',
                  canChangeDate: true,
                  deliveryDate: cart.remote.paymentOptions.deliveryDate,
                  productCode: cart.remote.description.productCode,
                  articleCode: cart.remote.description.articleCode,
                }),
              ]),
              billing: {
                title: 'cart.billing.title',
                description: 'cart.billing.description',
                right: useBillingContent(cart, deliveryInformation.billingAddress),
                productCode: cart.remote.description.productCode,
                articleCode: cart.remote.description.articleCode,
              },
            },
          ],
          showMissingEmails: false,
        }
      },
    }
  },
}

export default step
